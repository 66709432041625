var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "width": "100%",
      "mb": "1rem"
    }
  }, [_c('c-flex', {
    attrs: {
      "margin-bottom": "16px",
      "gap": "16px",
      "width": "100%",
      "align-items": "center"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "white-space": "nowrap"
    }
  }, [_vm._v(" List Tips Gizi ")]), _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('c-form-control', {
    attrs: {
      "pos": "relative",
      "w": "100%"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-search.svg'),
      "alt": "icon",
      "position": "absolute",
      "top": "50%",
      "transform": "translateY(-50%)",
      "left": "15px",
      "height": "24px",
      "z-index": "2"
    }
  }), _c('c-input', {
    attrs: {
      "id": "search",
      "type": "text",
      "w": "100%",
      "height": "62px",
      "placeholder": "Pencarian",
      "border-radius": "20px",
      "padding-left": "50px",
      "position": "relative",
      "z-index": "1"
    },
    on: {
      "keyup": _vm.onSearch
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1), _c('c-menu', {
    attrs: {
      "auto-select": false,
      "close-on-select": true
    },
    on: {
      "blur": true
    }
  }, [_c('c-menu-button', {
    attrs: {
      "background-color": "white",
      "color": "gray.900",
      "p": "0"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-filter.svg'),
      "height": "30",
      "width": "30",
      "fill": "#888888"
    }
  }), _vm.subCategoryParse.length > 0 ? _c('c-flex', {
    attrs: {
      "pos": "absolute",
      "top": "0",
      "right": "0",
      "width": "16px",
      "height": "16px",
      "align-items": "center",
      "justify-content": "center",
      "border-radius": "100%",
      "font-size": "10px",
      "background": "#ef4444",
      "color": "#FFF"
    }
  }, [_vm._v(" " + _vm._s(_vm.subCategoryParse.length) + " ")]) : _vm._e(), _vm.categoryParse.length > 0 ? _c('c-flex', {
    attrs: {
      "pos": "absolute",
      "top": "0",
      "right": "0",
      "width": "16px",
      "height": "16px",
      "align-items": "center",
      "justify-content": "center",
      "border-radius": "100%",
      "font-size": "10px",
      "background": "#ef4444",
      "color": "#FFF"
    }
  }, [_vm._v(" " + _vm._s(_vm.categoryParse.length) + " ")]) : _vm._e()], 1), _c('c-menu-list', {
    attrs: {
      "z-index": "10",
      "min-width": "250px",
      "max-width": "300px",
      "flex-direction": "column",
      "p": "0",
      "placement": "left-end"
    }
  }, [_vm._l(_vm.categories, function (category, i) {
    return _c('c-box', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.categories.length > 0,
        expression: "categories.length > 0"
      }],
      key: category.id
    }, [_c('c-menu-item', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            backgroundColor: 'primary.50'
          }
        },
        expression: "{\n              ':hover': {\n                backgroundColor: 'primary.50'\n              }\n            }"
      }],
      attrs: {
        "gap": "8px",
        "px": "1rem",
        "py": "8px",
        "cursor": "pointer",
        "background-color": _vm.categoryParse.includes(category.id) ? 'primary.50' : '#FFF'
      },
      on: {
        "click": function click($event) {
          return _vm.onChangeCategory(category.id);
        }
      }
    }, [_c('c-image', {
      attrs: {
        "src": category === null || category === void 0 ? void 0 : category.image,
        "width": "25px",
        "height": "25px"
      }
    }), _c('c-text', {
      attrs: {
        "color": "primary.400",
        "font-size": "18px"
      }
    }, [_vm._v(" " + _vm._s(category === null || category === void 0 ? void 0 : category.categoryName) + " ")])], 1), _vm._l(category === null || category === void 0 ? void 0 : category.subCategories, function (sub) {
      return _c('c-menu-group', {
        key: sub.id,
        attrs: {
          "title": ""
        }
      }, [_c('c-menu-item', {
        directives: [{
          name: "chakra",
          rawName: "v-chakra",
          value: {
            ':hover': {
              background: _vm.subCategoryParse.includes(sub.id) ? 'primary.50' : '#F2F2F2'
            }
          },
          expression: "{\n                ':hover': {\n                  background: subCategoryParse.includes(sub.id) ? 'primary.50' : '#F2F2F2',\n                }\n              }"
        }],
        attrs: {
          "d": "flex",
          "justify-content": "space-between",
          "pl": "48px",
          "pr": "8px",
          "background": _vm.subCategoryParse.includes(sub.id) ? 'primary.50' : '#FFF'
        },
        on: {
          "click": function click($event) {
            return _vm.onChangeFilter(sub === null || sub === void 0 ? void 0 : sub.id);
          }
        }
      }, [_vm._v(" " + _vm._s(sub === null || sub === void 0 ? void 0 : sub.subCategoryName) + " "), _c('inline-svg', {
        attrs: {
          "src": require('@/assets/icons/icon-chevron-right.svg'),
          "height": "16px",
          "width": "16px"
        }
      })], 1)], 1);
    }), _vm.categories.length !== i + 1 ? _c('c-menu-divider', {
      attrs: {
        "p": "0",
        "m": "0"
      }
    }) : _vm._e()], 2);
  }), _c('c-text', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.categories.length == 0,
      expression: "categories.length == 0"
    }],
    attrs: {
      "px": "1rem",
      "font-size": "16px",
      "font-weight": "500",
      "color": "danger.400"
    }
  }, [_vm._v(" Belum ada kategori ")])], 2)], 1)], 1), _c('c-box', {
    attrs: {
      "pos": "relative"
    }
  }, [_c('c-flex', {
    directives: [{
      name: "dragscroll",
      rawName: "v-dragscroll"
    }],
    attrs: {
      "flex-direction": "row",
      "align-items": "center",
      "flex-wrap": "no-wrap",
      "gap": "4px",
      "overflow": "hidden",
      "max-width": "890px",
      "pb": "8px"
    }
  }, [_c('c-box', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':hover': {
          backgroundColor: _vm.subCategoryParse.length == 0 ? _vm.categoryParse.length == 0 ? 'primary.50' : '#F2F2F2' : '#F2F2F2'
        }
      },
      expression: "{\n          ':hover': {\n            backgroundColor: subCategoryParse.length == 0 ? (categoryParse.length == 0 ? 'primary.50' : '#F2F2F2') : '#F2F2F2',\n          },\n        }"
    }],
    attrs: {
      "min-width": "130px",
      "max-width": "150px",
      "w": "100%",
      "border-radius": "8px",
      "background-color": _vm.subCategoryParse.length == 0 ? _vm.categoryParse.length == 0 ? 'primary.50' : '#FFF' : '#FFF',
      "text-align": "center",
      "p": "6px",
      "cursor": "pointer"
    },
    on: {
      "click": function click($event) {
        return _vm.onChangeCategory('all');
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": "https://ik.imagekit.io/dietela/pwa_webp/content_challenge/select%202.webp?updatedAt=1695810504668",
      "w": "60px",
      "h": "60px",
      "mb": "4px",
      "mx": "auto"
    }
  }), _c('c-text', {
    attrs: {
      "font-size": "12px",
      "color": "#111",
      "font-weight": "500"
    }
  }, [_vm._v(" Semua ")])], 1), _vm._l(_vm.categories, function (item) {
    return _c('c-box', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.categories.length > 0,
        expression: "categories.length > 0"
      }, {
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            backgroundColor: _vm.categoryParse.includes(item.id) ? 'primary.50' : '#F2F2F2'
          }
        },
        expression: "{\n          ':hover': {\n            backgroundColor: categoryParse.includes(item.id) ? 'primary.50' : '#F2F2F2',\n          },\n        }"
      }],
      key: item.id,
      attrs: {
        "min-width": "130px",
        "max-width": "150px",
        "w": "100%",
        "border-radius": "8px",
        "background-color": _vm.categoryParse.includes(item.id) ? 'primary.50' : '#FFF',
        "text-align": "center",
        "p": "6px",
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.onChangeCategory(item.id);
        }
      }
    }, [_c('c-image', {
      attrs: {
        "src": item === null || item === void 0 ? void 0 : item.image,
        "w": "60px",
        "h": "60px",
        "mb": "4px",
        "mx": "auto"
      }
    }), _c('c-text', {
      attrs: {
        "font-size": "12px",
        "color": "#111",
        "font-weight": "500"
      }
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.categoryName) + " ")])], 1);
  })], 2)], 1), _vm.items.length > 0 ? _c('BaseTable2', {
    attrs: {
      "columns": _vm.columns,
      "items": _vm.items,
      "page": _vm.page,
      "per-page": +_vm.perPage,
      "total-page": _vm.calculatePerPage(_vm.total, +_vm.perPage)
    },
    on: {
      "on-change-page": function onChangePage(page_) {
        return _vm.changePage(page_);
      },
      "on-change-per-page": function onChangePerPage(perPage_) {
        return _vm.changePerPage(perPage_);
      }
    }
  }) : _c('c-flex', {
    attrs: {
      "w": "100%",
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('c-image', {
    attrs: {
      "src": "https://ik.imagekit.io/dietela/pwa_webp/content_challenge/undraw_empty_re_opql%202.webp?updatedAt=1695810504720",
      "width": "300px",
      "mt": "3rem",
      "mb": "1rem"
    }
  }), _c('c-text', {
    attrs: {
      "font-size": ['16px', '18px'],
      "font-weight": "500",
      "text-align": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.isRoleNutritionist ? "Saat ini konten belum tersedia" : "Konten belum ada, silahkan tambahkan konten".concat(_vm.categoryParse.length > 0 || _vm.subCategoryParse.length > 0 ? ' untuk kategori ini' : '', ".")) + " ")]), _vm.isRoleAdmin ? _c('BaseButton', {
    attrs: {
      "mt": "1rem",
      "border-radius": "32px",
      "width": "300px"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'admin.coaching-challenge.add',
          params: {
            type: _vm.type
          }
        });
      }
    }
  }, [_vm._v(" Tambah Konten ")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }