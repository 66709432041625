<template>
  <c-box flex-grow="1">
    <BreadcrumbPath
      margin-bottom="20px"
      :paths="[
        {
          label: 'Manajemen Panduan Diet',
          href: '/admin/coaching-challenge',
        },
        {
          label: `${typeName}`,
          href: { name: 'admin.coaching-challenge', query: { type: type } },
          isCurrent: !$route.query.month
        },
        ...($route.query.month ? [{
          label: `Bulan ${$route.query.month}`,
          isCurrent: true
        }] : []),
      ]"
    />
    <c-box
      width="100%"
      max-width="1270px"
      background-color="#FFF"
      margin-bottom="16px"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      :padding="['16px', '30px']"
      :min-height="['unset', '74vh']"
      display="flex"
      flex-direction="column"
      align-items="center"
    >
      <c-flex
        mb="30px"
        justify="space-between"
        align="center"
        width="100%"
      >
        <BaseText
          size-mobile="20px"
          size-desktop="28px"
        >
          Manajemen Panduan Diet
        </BaseText>
        <c-flex gap="3">
          <BaseButton
            border-radius="16px"
            variant="outlined"
            @click="$router.push({ name: 'admin.coaching-challenge.category', params: { type: type } })"
          >
            Manajemen Kategori
          </BaseButton>
          <BaseButton
            :left-svg-icon="require('@/assets/icons/icon-plus.svg')"
            left-svg-icon-color="white"
            border-radius="16px"
            @click="$router.push({ name: 'admin.coaching-challenge.add', params: { type: type } })"
          >
            Tambah Konten
          </BaseButton>
        </c-flex>
      </c-flex>
      <ContentType 
        :type="type"
        @click="(value) => onChangeType(value)"
      />

      <c-box
        v-if="type === 'materi_harian' && !isLoadingListMonthMateriHarian"
        width="100%"
      >
        <MateriHarian
          :type="type"
          :months="listMonthMateriHarian"
          :items="items"
          :categories="category"
          :page="page"
          :per-page="perPage"
          :total="total"
          @refetch-content-month-group="(v) => refetchContentMonthGroup = v"
          @on-click="onChangeMonth"
        />
      </c-box>
      <c-box
        v-else-if="type === 'target_harian' && !isLoadingListMonthTargetHarian"
        width="100%"
      >
        <TargetHarian
          :type="type"
          :months="listMonthTargetHarian"
          :items="items"
          :categories="category"
          :page="page"
          :per-page="perPage"
          :total="total"
          @refetch-content-month-group="(v) => refetchContentMonthGroup = v"
          @on-click="onChangeMonth"
        />
      </c-box>

      <c-box
        v-else
        width="100%"
      >
        <TipsGizi
          :type="type"
          :items="items"
          :categories="category"
          :page="page"
          :per-page="perPage"
          :total="total"
        />
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import generalMixin from '@/utils/general-mixins'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import BaseText from '@/components/elements/base-text.vue'
import BaseButton from '@/components/elements/base-button.vue'
import ContentType from '@/views/admin/coaching-challenge/_widgets/content-type.vue'
import TipsGizi from '@/views/admin/coaching-challenge/_views/tips-gizi.vue'
import MateriHarian from '@/views/admin/coaching-challenge/_views/materi-harian.vue'
import TargetHarian from '@/views/admin/coaching-challenge/_views/target-harian.vue'
import { reqContentMonthGroup } from '@/requests/dietela-api/admin/req-content-month-group'

export default {
  name: 'ManagementCoachingChallengePage',
  components: {
    TipsGizi,
    TargetHarian,
    MateriHarian,
    BreadcrumbPath,
    BaseButton,
    BaseText,
    ContentType,
  },
  mixins: [generalMixin],
  data() {
    return {
      headings: [
        { label: 'no', width: '82px' },
        { label: 'judul konten', width: '200px' },
        { label: 'preview', width: '360px' },
        { label: 'kategori', width: '140px' },
        { label: 'action', width: '110px' },
      ],
      page: 1,
      perPage: '10',
      search: this.$route.query.q ?? '',
      selectedCategory: '',
      timer: null,
      type: this.$route.query.type,
      listMonthMateriHarian: [],
      listMonthTargetHarian: [],
      isLoadingListMonthMateriHarian: false,
      isLoadingListMonthTargetHarian: false,
      refetchContentMonthGroup: null,
    }
  },
  computed: {
    ...mapState({
      categoryCoaching: (s) => s.admContentChallenge.categoryCoaching,
    }),
    ...mapGetters({
      items: 'admContentChallenge/items',
      types: 'admContentChallenge/types',
      category: 'admContentChallenge/category',
      meta: 'admContentChallenge/meta',
    }),
    typeName() {
      if (this.type && this.types.length > 0) {
        return this.types.find((it) => it.type == this.type)?.name
      }
      return null
    },
    currentPage() {
      return parseInt(this.$route.query.page ?? 1, 10)
    },
    pages() {
      let total = this.meta.total ?? 1
      let perPage = parseInt(this.perPage) ?? 10
      let length = Math.ceil(total / perPage)
      return Array.from({ length }, (_, idx) => idx + 1)
    },
    total() {
      return this.meta.total ?? 1
    },
    typeId() {
      if (this.types.length > 0 && this.type) {
        return this.types.find((it) => it.type == this.type)?.id
      }
      return undefined
    },  
  },
  watch: {
    refetchContentMonthGroup: {
      immediate: true,
      async handler() {
        if (this.$route?.query?.type === 'target_harian') {
          this.listMonthTargetHarian = await reqContentMonthGroup(
            this.$store.getters.axios,
            { type: 'target_harian' },
          ).then((it) => it?.data?.data || [])
        } else if (this.$route?.query?.type === 'materi_harian') {
          this.listMonthMateriHarian = await reqContentMonthGroup(
            this.$store.getters.axios,
            { type: 'materi_harian' },
          ).then((it) => it?.data?.data || [])
        }
      },
    },
    type: {
      immediate: true,
      async handler(type) {
        this.isLoadingListMonthMateriHarian = true
        this.isLoadingListMonthTargetHarian = true
        if (type === 'target_harian' && this.listMonthTargetHarian?.length === 0) {
          this.listMonthTargetHarian = await reqContentMonthGroup(
            this.$store.getters.axios,
            { type: 'target_harian' },
          ).then((it) => it?.data?.data || [])
        } else if (type === 'materi_harian' && this.listMonthMateriHarian?.length === 0) {
          this.listMonthMateriHarian = await reqContentMonthGroup(
            this.$store.getters.axios,
            { type: 'materi_harian' },
          ).then((it) => it?.data?.data || [])
        }
        this.isLoadingListMonthMateriHarian = false
        this.isLoadingListMonthTargetHarian = false
      },
    },
    typeId: {
      handler(val) {
        if (val) {
          this.loadContentCategories()
        }
      },
    },
    $route: {
      immediate: true,
      handler(route) {
        if ((route.query.type || this.types.length > 0) && (route.query.month || (this.type != 'target_harian' && this.type != 'materi_harian'))) {
          this.page = route.query.page ? parseInt(route.query.page) : 1
          this.type = route.query.type ?? this.types[0]?.type
          this.perPage = route.query.perpage ?? '10'
          let params = new URLSearchParams()
          params.set('type', this.type)
          if (route.query.q) {
            params.set('q', route.query.q)
          }
          if (route.query.category && JSON.parse(route.query.category).length > 0) {
            params.set('category', route.query.category ?? '')
          }
          if (route.query.subCategories && JSON.parse(route.query.subCategories).length > 0) {
            params.set('subCategories', route.query.subCategories ?? '')
          }
          params.set('page', this.page)
          params.set('perpage', this.perPage)
          if (route.query.month) {
            params.set('monthGroup', route.query.month)
          }
          this.loadContentChallenge(params)
        }
      },
    },
  },
  async created() {
    if (this.types.length == 0) {
      await this.getContentTypes()
    }
    if (!this.type) {
      this.onChangeType(this.types[0].type)
    }
  },
  methods: {
    ...mapActions({
      loadContentChallenge: 'admContentChallenge/listContentChallengeAdmin',
      getContentTypes: 'admContentChallenge/getContentTypes',
      getContentCategory: 'admContentChallenge/getContentCategory',
    }),
    onChangeType(value) {
      this.type = value
      this.$router.push({
        name: this.$route.name,
        query: {
          type: value,
          page: 1,
          perpage: this.perPage,
        },
      }).catch(() => {})
    },
    onChangeMonth(value) {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          month: value,
          page: 1,
        },
      }).catch(() => {})
    },
    loadContentCategories() {
      let params = new URLSearchParams()
      params.set('type', this.typeId)
      params.set('page', 1)
      params.set('perpage', 10)
      params.set('subCategories', 1)
      this.getContentCategory(params)
    },
  },
}
</script>
