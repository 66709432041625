var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [!_vm.selectedMonth ? _c('c-grid', {
    attrs: {
      "template-columns": "repeat(2, 1fr)",
      "gap": "6"
    }
  }, _vm._l(_vm.months, function (item) {
    return _c('c-box', {
      key: item.id,
      staticClass: "card",
      attrs: {
        "border-radius": "8px",
        "width": "100%",
        "box-shadow": "0px 4px 8px rgba(0, 0, 0, 0.2)",
        "cursor": "pointer",
        "transition": "all 0.2s ease-in-out",
        "position": "relative",
        "_hover": {
          backgroundColor: '#e8e7e7'
        }
      },
      on: {
        "click": function click($event) {
          return _vm.clickDetailContent(item.monthGroup);
        }
      }
    }, [_vm.isRoleAdmin ? _c('c-menu', {
      key: item.id
    }, [_c('c-menu-button', {
      staticClass: "card-options",
      attrs: {
        "position": "absolute",
        "right": "8px",
        "top": "5px",
        "z-index": "1",
        "opacity": "0",
        "variant": "ghost",
        "variant-color": "primary",
        "padding": "0"
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
        }
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": require('@/assets/icons/icon-option-2.svg'),
        "height": "40",
        "width": "40",
        "fill": "white",
        "stroke": "white"
      }
    })], 1), _c('c-menu-list', [_c('c-menu-item', {
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return function () {
            _vm.selectedEditDataCopy = Object.assign({}, _vm.selectedEditDataCopy, item);
            _vm.isOpenModalEdit = true;
          }.apply(null, arguments);
        }
      }
    }, [_vm._v(" Edit ")])], 1)], 1) : _vm._e(), _c('c-box', {
      attrs: {
        "position": "relative",
        "height": "120px",
        "border-radius": "8px",
        "overflow": "hidden",
        "background-image": "linear-gradient(180deg, rgba(0, 0, 0, 0.22) 37.06%, rgba(0, 0, 0, 0.40) 70.22%), url('".concat(item.image, "')"),
        "background-size": "cover",
        "background-position": "center"
      }
    }, [_c('c-box', {
      attrs: {
        "position": "absolute",
        "bottom": "15%",
        "left": "6%"
      }
    }, [_c('BaseText', {
      attrs: {
        "size-mobile": "18px",
        "size-desktop": "20px",
        "color": "white"
      }
    }, [_vm._v(" Bulan " + _vm._s(item.monthGroup) + " ")])], 1)], 1), _c('c-box', {
      attrs: {
        "padding": "10px"
      }
    }, [_c('BaseText', {
      attrs: {
        "size-mobile": "16px",
        "size-desktop": "18px",
        "color": "#008C81"
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('BaseText', {
      attrs: {
        "size-mobile": "12px",
        "size-desktop": "14px-2",
        "margin-top": "12px"
      }
    }, [_vm._v(" " + _vm._s(item.description) + " ")])], 1)], 1);
  }), 1) : _c('c-box', {
    attrs: {
      "width": "100%",
      "mb": "1rem"
    }
  }, [_c('c-flex', {
    attrs: {
      "margin-bottom": "16px",
      "gap": "16px",
      "width": "100%",
      "align-items": "center"
    }
  }, [_c('c-flex', {
    attrs: {
      "align-items": "center",
      "gap": "8px"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "ghost",
      "p": "0"
    },
    on: {
      "click": _vm.onBack
    }
  }, [_c('c-image', {
    attrs: {
      "w": "24px",
      "h": "24px",
      "object-fit": "cover",
      "src": require('@/assets/icons/icon-chevron-left.svg')
    }
  })], 1), _c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "white-space": "nowrap"
    }
  }, [_vm._v(" Bulan " + _vm._s(_vm.selectedMonth) + " ")])], 1), _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('c-form-control', {
    attrs: {
      "pos": "relative",
      "w": "100%"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-search.svg'),
      "alt": "icon",
      "position": "absolute",
      "top": "50%",
      "transform": "translateY(-50%)",
      "left": "15px",
      "height": "24px",
      "z-index": "2"
    }
  }), _c('c-input', {
    attrs: {
      "id": "search",
      "type": "text",
      "w": "100%",
      "height": "62px",
      "placeholder": "Pencarian",
      "border-radius": "20px",
      "padding-left": "50px",
      "position": "relative",
      "z-index": "1"
    },
    on: {
      "keyup": _vm.onSearch
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1), _c('c-menu', {
    attrs: {
      "auto-select": false,
      "close-on-select": true
    },
    on: {
      "blur": true
    }
  }, [_c('c-menu-button', {
    attrs: {
      "background-color": "white",
      "color": "gray.900",
      "p": "0"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-filter.svg'),
      "height": "30",
      "width": "30",
      "fill": "#888888"
    }
  }), _vm.subCategoryParse.length > 0 ? _c('c-flex', {
    attrs: {
      "pos": "absolute",
      "top": "0",
      "right": "0",
      "width": "16px",
      "height": "16px",
      "align-items": "center",
      "justify-content": "center",
      "border-radius": "100%",
      "font-size": "10px",
      "background": "#ef4444",
      "color": "#FFF"
    }
  }, [_vm._v(" " + _vm._s(_vm.subCategoryParse.length) + " ")]) : _vm._e(), _vm.categoryParse.length > 0 ? _c('c-flex', {
    attrs: {
      "pos": "absolute",
      "top": "0",
      "right": "0",
      "width": "16px",
      "height": "16px",
      "align-items": "center",
      "justify-content": "center",
      "border-radius": "100%",
      "font-size": "10px",
      "background": "#ef4444",
      "color": "#FFF"
    }
  }, [_vm._v(" " + _vm._s(_vm.categoryParse.length) + " ")]) : _vm._e()], 1), _c('c-menu-list', {
    attrs: {
      "z-index": "10",
      "min-width": "250px",
      "max-width": "300px",
      "flex-direction": "column",
      "p": "0",
      "placement": "left-end"
    }
  }, [_vm._l(_vm.categories, function (category, i) {
    return _c('c-box', {
      key: category.id
    }, [_c('c-menu-item', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            backgroundColor: 'primary.50'
          }
        },
        expression: "{\n                ':hover': {\n                  backgroundColor: 'primary.50'\n                }\n              }"
      }],
      attrs: {
        "gap": "8px",
        "px": "1rem",
        "py": "8px",
        "cursor": "pointer",
        "background-color": _vm.categoryParse.includes(category.id) ? 'primary.50' : '#FFF'
      },
      on: {
        "click": function click($event) {
          return _vm.onChangeCategory(category.id);
        }
      }
    }, [_c('c-image', {
      attrs: {
        "src": category === null || category === void 0 ? void 0 : category.image,
        "width": "25px",
        "height": "25px"
      }
    }), _c('c-text', {
      attrs: {
        "color": "primary.400",
        "font-size": "18px"
      }
    }, [_vm._v(" " + _vm._s(category === null || category === void 0 ? void 0 : category.categoryName) + " ")])], 1), _vm._l(category === null || category === void 0 ? void 0 : category.subCategories, function (sub) {
      return _c('c-menu-group', {
        key: sub.id,
        attrs: {
          "title": ""
        }
      }, [_c('c-menu-item', {
        directives: [{
          name: "chakra",
          rawName: "v-chakra",
          value: {
            ':hover': {
              background: _vm.subCategoryParse.includes(sub.id) ? 'primary.50' : '#F2F2F2'
            }
          },
          expression: "{\n                  ':hover': {\n                    background: subCategoryParse.includes(sub.id) ? 'primary.50' : '#F2F2F2',\n                  }\n                }"
        }],
        attrs: {
          "d": "flex",
          "justify-content": "space-between",
          "pl": "48px",
          "pr": "8px",
          "background": _vm.subCategoryParse.includes(sub.id) ? 'primary.50' : '#FFF'
        },
        on: {
          "click": function click($event) {
            return _vm.onChangeFilter(sub === null || sub === void 0 ? void 0 : sub.id);
          }
        }
      }, [_vm._v(" " + _vm._s(sub === null || sub === void 0 ? void 0 : sub.subCategoryName) + " "), _c('inline-svg', {
        attrs: {
          "src": require('@/assets/icons/icon-chevron-right.svg'),
          "height": "16px",
          "width": "16px"
        }
      })], 1)], 1);
    }), _vm.categories.length !== i + 1 ? _c('c-menu-divider', {
      attrs: {
        "p": "0",
        "m": "0"
      }
    }) : _vm._e()], 2);
  }), _c('c-text', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.categories.length == 0,
      expression: "categories.length == 0"
    }],
    attrs: {
      "px": "1rem",
      "font-size": "16px",
      "font-weight": "500",
      "color": "danger.400"
    }
  }, [_vm._v(" Belum ada sub kategori ")])], 2)], 1)], 1), _c('c-box', {
    attrs: {
      "pos": "relative"
    }
  }, [_c('c-flex', {
    directives: [{
      name: "dragscroll",
      rawName: "v-dragscroll"
    }],
    attrs: {
      "flex-direction": "row",
      "align-items": "center",
      "flex-wrap": "no-wrap",
      "gap": "4px",
      "overflow": "hidden",
      "max-width": "890px",
      "pb": "8px"
    }
  }, [_c('c-box', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':hover': {
          backgroundColor: _vm.subCategoryParse.length == 0 ? _vm.categoryParse.length == 0 ? 'primary.50' : '#F2F2F2' : '#F2F2F2'
        }
      },
      expression: "{\n            ':hover': {\n              backgroundColor: subCategoryParse.length == 0 ? (categoryParse.length == 0 ? 'primary.50' : '#F2F2F2') : '#F2F2F2',\n            },\n          }"
    }],
    attrs: {
      "min-width": "130px",
      "w": "100%",
      "max-width": "150px",
      "border-radius": "8px",
      "background-color": _vm.subCategoryParse.length == 0 ? _vm.categoryParse.length == 0 ? 'primary.50' : '#FFF' : '#FFF',
      "text-align": "center",
      "p": "6px",
      "cursor": "pointer"
    },
    on: {
      "click": function click($event) {
        return _vm.onChangeCategory('all');
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": "https://ik.imagekit.io/dietela/pwa_webp/content_challenge/select%202.webp?updatedAt=1695810504668",
      "w": "60px",
      "h": "60px",
      "mb": "4px",
      "mx": "auto"
    }
  }), _c('c-text', {
    attrs: {
      "font-size": "12px",
      "color": "#111",
      "font-weight": "500"
    }
  }, [_vm._v(" Semua ")])], 1), _vm._l(_vm.categories, function (item) {
    return _c('c-box', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.categories.length > 0,
        expression: "categories.length > 0"
      }, {
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            backgroundColor: _vm.categoryParse.includes(item.id) ? 'primary.50' : '#F2F2F2'
          }
        },
        expression: "{\n            ':hover': {\n              backgroundColor: categoryParse.includes(item.id) ? 'primary.50' : '#F2F2F2',\n            },\n          }"
      }],
      key: item.id,
      attrs: {
        "min-width": "130px",
        "w": "100%",
        "max-width": "150px",
        "border-radius": "8px",
        "background-color": _vm.categoryParse.includes(item.id) ? 'primary.50' : '#FFF',
        "text-align": "center",
        "p": "6px",
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.onChangeCategory(item.id);
        }
      }
    }, [_c('c-image', {
      attrs: {
        "src": item === null || item === void 0 ? void 0 : item.image,
        "w": "60px",
        "h": "60px",
        "mb": "4px",
        "mx": "auto"
      }
    }), _c('c-text', {
      attrs: {
        "font-size": "12px",
        "color": "#111",
        "font-weight": "500"
      }
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.categoryName) + " ")])], 1);
  })], 2)], 1), _vm.items.length > 0 ? _c('BaseTable2', {
    attrs: {
      "columns": _vm.columns,
      "items": _vm.items,
      "page": _vm.page,
      "per-page": +_vm.perPage,
      "total-page": _vm.calculatePerPage(_vm.total, +_vm.perPage)
    },
    on: {
      "on-change-page": function onChangePage(page_) {
        return _vm.changePage(page_);
      },
      "on-change-per-page": function onChangePerPage(perPage_) {
        return _vm.changePerPage(perPage_);
      }
    }
  }) : _c('c-flex', {
    attrs: {
      "w": "100%",
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('c-image', {
    attrs: {
      "src": "https://ik.imagekit.io/dietela/pwa_webp/content_challenge/undraw_empty_re_opql%202.webp?updatedAt=1695810504720",
      "width": "300px",
      "mt": "3rem",
      "mb": "1rem"
    }
  }), _c('c-text', {
    attrs: {
      "font-size": ['16px', '18px'],
      "font-weight": "500",
      "text-align": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.isRoleAdmin ? "Konten belum ada, silahkan tambahkan konten".concat(_vm.categoryParse.length > 0 || _vm.subCategoryParse.length > 0 ? ' untuk bulan ini' : '', ".") : "Saat ini konten belum tersedia") + " ")]), _vm.isRoleAdmin ? _c('BaseButton', {
    attrs: {
      "mt": "1rem",
      "border-radius": "32px",
      "width": "300px"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'admin.coaching-challenge.add',
          params: {
            type: _vm.type
          }
        });
      }
    }
  }, [_vm._v(" Tambah Konten ")]) : _vm._e()], 1)], 1), _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpenModalEdit,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "margin-top": "24px"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "20px",
            "size-desktop": "28px",
            "text-align": "center"
          }
        }, [_vm._v(" Edit ")])], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "padding": "0 24px"
          }
        }, [_c('BaseInputText', {
          attrs: {
            "label": "Judul",
            "placeholder": "Masukkan Judul",
            "full-width": "",
            "is-required": ""
          },
          model: {
            value: _vm.selectedEditDataCopy.title,
            callback: function callback($$v) {
              _vm.$set(_vm.selectedEditDataCopy, "title", $$v);
            },
            expression: "selectedEditDataCopy.title"
          }
        }), _c('BaseInputText', {
          attrs: {
            "label": "Deskripsi",
            "placeholder": "Masukkan Deskripsi",
            "full-width": "",
            "is-required": ""
          },
          model: {
            value: _vm.selectedEditDataCopy.description,
            callback: function callback($$v) {
              _vm.$set(_vm.selectedEditDataCopy, "description", $$v);
            },
            expression: "selectedEditDataCopy.description"
          }
        }), _c('c-box', {
          staticClass: "item",
          attrs: {
            "min-height": "150px",
            "width": "100%",
            "mx": "auto"
          }
        }, [!_vm.isDataEmpty(_vm.selectedEditDataCopy.image) ? _c('c-box', {
          attrs: {
            "position": "relative",
            "d": "inline-block",
            "w": "100%"
          }
        }, [_c('c-image', {
          staticStyle: {
            "object-fit": "cover"
          },
          attrs: {
            "src": _vm.getPreviewImage,
            "width": "100%",
            "max-height": "120px",
            "rounded": "0"
          }
        }), _c('c-button', {
          attrs: {
            "width": "100%",
            "display": "flex",
            "align": "center",
            "justify": "center",
            "gap": "8px"
          },
          on: {
            "click": _vm.handleOpenFile
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-image-preview.svg'),
            "height": "15px",
            "width": "15px",
            "fill": "#1F2937"
          }
        }), _vm._v(" Ganti Gambar ")], 1)], 1) : _vm._e(), _c('c-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.isDataEmpty(_vm.selectedEditDataCopy.image),
            expression: "isDataEmpty(selectedEditDataCopy.image)"
          }],
          attrs: {
            "p": "1rem",
            "h": "fit-content",
            "min-height": "150px",
            "w": "100%",
            "as": "label",
            "d": "flex",
            "border": "1px solid #C4C4C4",
            "justify": "center",
            "rounded": "8px",
            "overflow": "hidden",
            "bg": "white",
            "cursor": "pointer"
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-image-preview.svg'),
            "height": "100px",
            "width": "100px",
            "fill": "#008C81"
          }
        }), _c('input', {
          ref: "file-",
          attrs: {
            "type": "file",
            "accept": ".jpg, .jpeg, .png",
            "hidden": ""
          },
          on: {
            "change": function change($event) {
              return _vm.handleChangePhoto('input-photo', $event);
            }
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin": "24px 0"
          }
        }, [_c('c-flex', {
          attrs: {
            "gap": "8px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalEdit = false;
            }
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "is-loading": _vm.isLoadingEdit
          },
          on: {
            "click": _vm.onSubmit
          }
        }, [_vm._v(" Simpan ")])], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpenModalEditSuccess,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('c-image', {
          attrs: {
            "height": "160px",
            "object-fit": "cover",
            "src": "https://ik.imagekit.io/dietela/pwa_webp/popup/berhasil_data.png?updatedAt=1690890254620",
            "alt": "image success delete coupon"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "justify-content": "center",
            "padding": "30px 24px"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "18px",
            "size-desktop": "20px",
            "color": "primary.400"
          }
        }, [_vm._v(" Data berhasil diubah! ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": function click() {
              _vm.isOpenModalEditSuccess = false;
            }
          }
        }, [_vm._v(" Oke ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }