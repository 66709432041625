import { render, staticRenderFns } from "./target-harian.vue?vue&type=template&id=2470111c&scoped=true&"
import script from "./target-harian.vue?vue&type=script&lang=js&"
export * from "./target-harian.vue?vue&type=script&lang=js&"
import style0 from "./target-harian.vue?vue&type=style&index=0&id=2470111c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2470111c",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CMenuButton: require('@chakra-ui/vue').CMenuButton, CMenuItem: require('@chakra-ui/vue').CMenuItem, CMenuList: require('@chakra-ui/vue').CMenuList, CMenu: require('@chakra-ui/vue').CMenu, CBox: require('@chakra-ui/vue').CBox, CGrid: require('@chakra-ui/vue').CGrid, CImage: require('@chakra-ui/vue').CImage, CButton: require('@chakra-ui/vue').CButton, CFlex: require('@chakra-ui/vue').CFlex, CInput: require('@chakra-ui/vue').CInput, CFormControl: require('@chakra-ui/vue').CFormControl, CText: require('@chakra-ui/vue').CText, CMenuGroup: require('@chakra-ui/vue').CMenuGroup, CMenuDivider: require('@chakra-ui/vue').CMenuDivider})
