<template>
  <c-box>
    <c-grid
      v-if="!selectedMonth"
      template-columns="repeat(2, 1fr)"
      gap="6"
    >
      <c-box
        v-for="(item) in months"
        :key="item.id"
        class="card"
        border-radius="8px"
        width="100%"
        box-shadow="0px 4px 8px rgba(0, 0, 0, 0.2)"
        cursor="pointer"
        transition="all 0.2s ease-in-out"
        position="relative"
        :_hover="{
          backgroundColor: '#e8e7e7',
        }"
        @click="clickDetailContent(item.monthGroup)"
      >
        <c-menu
          v-if="isRoleAdmin"
          :key="item.id"
        >
          <c-menu-button
            class="card-options"
            position="absolute"
            right="8px"
            top="5px"
            z-index="1"
            opacity="0"
            variant="ghost"
            variant-color="primary"
            padding="0"
            @click.stop
          >
            <inline-svg
              :src="require('@/assets/icons/icon-option-2.svg')"
              height="40"
              width="40"
              fill="white"
              stroke="white"
            />
          </c-menu-button>
          <c-menu-list>
            <c-menu-item
              @click.stop="() => {
                selectedEditDataCopy = {...selectedEditDataCopy, ...item}
                isOpenModalEdit = true
              }"
            >
              Edit
            </c-menu-item>
          </c-menu-list>
        </c-menu>

        <c-box
          position="relative"
          height="120px"
          border-radius="8px"
          overflow="hidden"
          :background-image="`linear-gradient(180deg, rgba(0, 0, 0, 0.22) 37.06%, rgba(0, 0, 0, 0.40) 70.22%), url('${item.image}')`"
          background-size="cover"
          background-position="center"
        >
          <!--          <c-box background="red">-->
          <!--            <c-image-->
          <!--              :src="item.image"-->
          <!--              style="object-fit: cover;"-->
          <!--              width="100%"-->
          <!--              max-height="120px"-->
          <!--              rounded="0"-->
          <!--            />-->
          <!--          </c-box>-->

          <c-box
            position="absolute"
            bottom="15%"
            left="6%"
          >
            <BaseText
              size-mobile="18px"
              size-desktop="20px"
              color="white"
            >
              Bulan {{ item.monthGroup }}
            </BaseText>
          </c-box>
        </c-box>
        <c-box padding="10px">
          <BaseText
            size-mobile="16px"
            size-desktop="18px"
            color="#008C81"
          >
            {{ item.title }}
          </BaseText>
          <BaseText
            size-mobile="12px"
            size-desktop="14px-2"
            margin-top="12px"
          >
            {{ item.description }}
          </BaseText>
        </c-box>
      </c-box>
    </c-grid>
    <c-box
      v-else
      width="100%"
      mb="1rem"
    >
      <c-flex
        margin-bottom="16px"
        gap="16px"
        width="100%"
        align-items="center"
      >
        <c-flex
          align-items="center"
          gap="8px"
        >
          <c-button
            variant="ghost"
            p="0"
            @click="onBack"
          >
            <c-image
              w="24px"
              h="24px"
              object-fit="cover"
              :src="require('@/assets/icons/icon-chevron-left.svg')"
            />
          </c-button>
          <BaseText
            size-mobile="18px"
            size-desktop="20px"
            white-space="nowrap"
          >
            Bulan {{ selectedMonth }}
          </BaseText>
        </c-flex>
        <c-box width="100%">
          <c-form-control
            pos="relative"
            w="100%"
          >
            <c-image
              :src="require('@/assets/ic-search.svg')"
              alt="icon"
              position="absolute"
              top="50%"
              transform="translateY(-50%)"
              left="15px"
              height="24px"
              z-index="2"
            />
            <c-input
              id="search"
              v-model="search"
              type="text"
              w="100%"
              height="62px"
              placeholder="Pencarian"
              border-radius="20px"
              padding-left="50px"
              position="relative"
              z-index="1"
              @keyup="onSearch"
            />
          </c-form-control>
        </c-box>

        <c-menu
          :auto-select="false"
          :close-on-select="true"
          @blur="true"
        >
          <c-menu-button
            background-color="white"
            color="gray.900"
            p="0"
          >
            <inline-svg
              :src="require('@/assets/icons/icon-filter.svg')"
              height="30"
              width="30"
              fill="#888888"
            />
            <c-flex
              v-if="subCategoryParse.length > 0"
              pos="absolute"
              top="0"
              right="0"
              width="16px"
              height="16px"
              align-items="center"
              justify-content="center"
              border-radius="100%"
              font-size="10px"
              background="#ef4444"
              color="#FFF"
            >
              {{ subCategoryParse.length }}
            </c-flex>
            <c-flex
              v-if="categoryParse.length > 0"
              pos="absolute"
              top="0"
              right="0"
              width="16px"
              height="16px"
              align-items="center"
              justify-content="center"
              border-radius="100%"
              font-size="10px"
              background="#ef4444"
              color="#FFF"
            >
              {{ categoryParse.length }}
            </c-flex>
          </c-menu-button>
          <c-menu-list
            z-index="10"
            min-width="250px"
            max-width="300px"
            flex-direction="column"
            p="0"
            placement="left-end"
          >
            <c-box
              v-for="(category, i) in categories"
              :key="category.id"
            >
              <c-menu-item
                v-chakra="{
                  ':hover': {
                    backgroundColor: 'primary.50'
                  }
                }"
                gap="8px"
                px="1rem"
                py="8px"
                cursor="pointer"
                :background-color="categoryParse.includes(category.id) ? 'primary.50' : '#FFF'"
                @click="onChangeCategory(category.id)"
              >
                <c-image
                  :src="category?.image"
                  width="25px"
                  height="25px"
                />
                <c-text
                  color="primary.400"
                  font-size="18px"
                >
                  {{ category?.categoryName }}
                </c-text>
              </c-menu-item>
              <c-menu-group
                v-for="sub in category?.subCategories"
                :key="sub.id"
                title=""
              >
                <c-menu-item
                  v-chakra="{
                    ':hover': {
                      background: subCategoryParse.includes(sub.id) ? 'primary.50' : '#F2F2F2',
                    }
                  }"
                  d="flex"
                  justify-content="space-between"
                  pl="48px"
                  pr="8px"
                  :background="subCategoryParse.includes(sub.id) ? 'primary.50' : '#FFF'"
                  @click="onChangeFilter(sub?.id)"
                >
                  {{ sub?.subCategoryName }}
                  <inline-svg
                    :src="require('@/assets/icons/icon-chevron-right.svg')"
                    height="16px"
                    width="16px"
                  />
                </c-menu-item>
              </c-menu-group>
              <c-menu-divider
                v-if="categories.length !== (i+1)"
                p="0"
                m="0"
              />
            </c-box>
            <c-text
              v-show="categories.length == 0"
              px="1rem"
              font-size="16px"
              font-weight="500"
              color="danger.400"
            >
              Belum ada sub kategori
            </c-text>
          </c-menu-list>
        </c-menu>
      </c-flex>

      <c-box pos="relative">
        <c-flex
          v-dragscroll
          flex-direction="row"
          align-items="center"
          flex-wrap="no-wrap"
          gap="4px"
          overflow="hidden"
          max-width="890px"
          pb="8px"
        >
          <c-box
            v-chakra="{
              ':hover': {
                backgroundColor: subCategoryParse.length == 0 ? (categoryParse.length == 0 ? 'primary.50' : '#F2F2F2') : '#F2F2F2',
              },
            }"
            min-width="130px"
            w="100%"
            max-width="150px"
            border-radius="8px"
            :background-color="subCategoryParse.length == 0 ? (categoryParse.length == 0 ? 'primary.50' : '#FFF') : '#FFF'"
            text-align="center"
            p="6px"
            cursor="pointer"
            @click="onChangeCategory('all')"
          >
            <c-image
              src="https://ik.imagekit.io/dietela/pwa_webp/content_challenge/select%202.webp?updatedAt=1695810504668"
              w="60px"
              h="60px"
              mb="4px"
              mx="auto"
            />
            <c-text
              font-size="12px"
              color="#111"
              font-weight="500"
            >
              Semua
            </c-text>
          </c-box>
          <c-box
            v-for="item in categories"
            v-show="categories.length > 0"
            :key="item.id"
            v-chakra="{
              ':hover': {
                backgroundColor: categoryParse.includes(item.id) ? 'primary.50' : '#F2F2F2',
              },
            }"
            min-width="130px"
            w="100%"
            max-width="150px"
            border-radius="8px"
            :background-color="categoryParse.includes(item.id) ? 'primary.50' : '#FFF'"
            text-align="center"
            p="6px"
            cursor="pointer"
            @click="onChangeCategory(item.id)"
          >
            <c-image
              :src="item?.image"
              w="60px"
              h="60px"
              mb="4px"
              mx="auto"
            />
            <c-text
              font-size="12px"
              color="#111"
              font-weight="500"
            >
              {{ item?.categoryName }}
            </c-text>
          </c-box>
        </c-flex>
      </c-box>

      <BaseTable2
        v-if="items.length > 0"
        :columns="columns"
        :items="items"
        :page="page"
        :per-page="+perPage"
        :total-page="calculatePerPage(total, +perPage)"
        @on-change-page="(page_) => changePage(page_)"
        @on-change-per-page="(perPage_) => changePerPage(perPage_)"
      />
      <c-flex
        v-else
        w="100%"
        flex-direction="column"
        justify-content="center"
        align-items="center"
      >
        <c-image
          src="https://ik.imagekit.io/dietela/pwa_webp/content_challenge/undraw_empty_re_opql%202.webp?updatedAt=1695810504720"
          width="300px"
          mt="3rem"
          mb="1rem"
        />
        <c-text
          :font-size="['16px', '18px']"
          font-weight="500"
          text-align="center"
        >
          {{ isRoleAdmin ? (`Konten belum ada, silahkan tambahkan konten${categoryParse.length > 0 || subCategoryParse.length > 0 ? ' untuk bulan ini' : ''}.`) : `Saat ini konten belum tersedia` }}
        </c-text>
        <BaseButton
          v-if="isRoleAdmin"
          mt="1rem"
          border-radius="32px"
          width="300px"
          @click="$router.push({
            name: 'admin.coaching-challenge.add',
            params: {
              type: type,
            },
          })"
        >
          Tambah Konten
        </BaseButton>
      </c-flex>
    </c-box>

    <!-- MODAL -->
    <BaseModal
      :is-open="isOpenModalEdit"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box margin-top="24px">
          <BaseText
            size-mobile="20px"
            size-desktop="28px"
            text-align="center"
          >
            Edit
          </BaseText>
        </c-box>
      </template>
      <template #body>
        <c-box padding="0 24px">
          <BaseInputText
            v-model="selectedEditDataCopy.title"
            label="Judul"
            placeholder="Masukkan Judul"
            full-width
            is-required
          />
          <!--              :is-invalid="isInvalidField($v.name)"-->
          <!--              :invalid-text="parseErrors('Nama kupon', $v.name)"-->
          <!--              @blur="$v.name.$touch"-->
          <BaseInputText
            v-model="selectedEditDataCopy.description"
            label="Deskripsi"
            placeholder="Masukkan Deskripsi"
            full-width
            is-required
          />

          <c-box
            min-height="150px"
            width="100%"
            mx="auto"
            class="item"
          >
            <c-box
              v-if="!isDataEmpty(selectedEditDataCopy.image)"
              position="relative"
              d="inline-block"
              w="100%"
            >
              <c-image
                :src="getPreviewImage"
                style="object-fit: cover;"
                width="100%"
                max-height="120px"
                rounded="0"
              />
              <c-button
                width="100%"
                display="flex"
                align="center"
                justify="center"
                gap="8px"
                @click="handleOpenFile"
              >
                <inline-svg
                  :src="require('@/assets/icons/icon-image-preview.svg')"
                  height="15px"
                  width="15px"
                  fill="#1F2937"
                />
                Ganti Gambar
              </c-button>
            </c-box>
            <c-button
              v-show="isDataEmpty(selectedEditDataCopy.image)"
              p="1rem"
              h="fit-content"
              min-height="150px"
              w="100%"
              as="label"
              d="flex"
              border="1px solid #C4C4C4"
              justify="center"
              rounded="8px"
              overflow="hidden"
              bg="white"
              cursor="pointer"
            >
              <inline-svg
                :src="require('@/assets/icons/icon-image-preview.svg')"
                height="100px"
                width="100px"
                fill="#008C81"
              />
              <input
                ref="file-"
                type="file"
                accept=".jpg, .jpeg, .png"
                hidden
                @change="handleChangePhoto('input-photo', $event)"
              >
            </c-button>
          </c-box>
        </c-box>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin="24px 0"
        >
          <c-flex gap="8px">
            <BaseButton
              :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
              left-svg-icon-color="#008C81"
              color="primary"
              rounded="1000px"
              width="100%"
              variant="outlined"
              @click="isOpenModalEdit = false"
            >
              Batal
            </BaseButton>
            <BaseButton
              :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
              right-svg-icon-color="white"
              color="primary"
              rounded="1000px"
              width="100%"
              :is-loading="isLoadingEdit"
              @click="onSubmit"
            >
              Simpan
            </BaseButton>
          </c-flex>
        </c-box>
      </template>
    </BaseModal>

    <BaseModal
      :is-open="isOpenModalEditSuccess"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="160px"
            object-fit="cover"
            src="https://ik.imagekit.io/dietela/pwa_webp/popup/berhasil_data.png?updatedAt=1690890254620"
            alt="image success delete coupon"
          />
        </c-box>
      </template>
      <template #body>
        <c-flex
          justify-content="center"
          padding="30px 24px"
        >
          <BaseText
            size-mobile="18px"
            size-desktop="20px"
            color="primary.400"
          >
            Data berhasil diubah!
          </BaseText>
        </c-flex>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="primary"
            rounded="1000px"
            width="100%"
            @click="() => {
              isOpenModalEditSuccess = false
            }"
          >
            Oke
          </BaseButton>
        </c-box>
      </template>
    </BaseModal>
  </c-box>
</template>

<script>
import { dragscroll } from 'vue-dragscroll'
import BaseText from '@/components/elements/base-text.vue'
import BaseTable2 from '@/components/elements/base-table-2.vue'
import BaseButton from '@/components/elements/base-button.vue'
import BaseModal from '@/components/elements/base-modal.vue'
import BaseInputText from '@/components/elements/base-input-text.vue'
import { isDataEmpty } from '@/utils/is-data-empty'
import { reqUpdateContentMonthGroup } from '@/requests/dietela-api/admin/req-update-content-month-group'
import { reqUploadImageContentAndChallenge } from '@/requests/dietela-api/admin/req-upload-image-content-and-challenge'

export default {
  name: 'MateriHarian',
  components: {
    BaseInputText,
    BaseModal,
    BaseText,
    BaseTable2,
    BaseButton,
  },
  directives: {
    dragscroll,
  },
  props: {
    type: {
      type: String,
      default: 'materi-harian',
    },
    months: {
      type: Array,
      default: () => [],
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
    categories: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: String,
      default: '10',
    },
    refetchContentMonthGroup: {
      type: [String, Number],
      default: null,
    },
  },
  emits: ['on-click'],
  data() {
    return {
      isLoadingEdit: false,
      isOpenModalEdit: false,
      isOpenModalEditSuccess: false,
      selectedEditDataCopy: {
        id: '',
        title: '',
        description: '',
        image: '',
        newImage: '',
      },
      columns: [
        {
          id: 'no',
          label: 'NO',
          type: 'AUTO_INCREMENT',
          align: 'center',
          itemAlign: 'center',
          width: '30px',
        },
        {
          id: 'title',
          label: 'JUDUL KONTEN',
          type: 'TEXT',
          width: '200px',
        },
        {
          id: 'preview',
          label: 'PREVIEW',
          type: 'CUSTOM',
          width: '250px',
          customRender: (item) => {
            if (item?.coverImage) {
              return `<div style="display: flex; align-items: center; ">
                  <img src="${item?.coverImage}" width="60" height="60px" style="margin-right: 1rem;" />
                  <div class="line-clamp-3" style="font-size: 14px;">${item?.description}</div>
                </div>
              `
            }
            return `<div class="line-clamp-3" style="font-size: 14px;">${item?.description}</div>`
          },
        },
        {
          id: 'category',
          label: 'KATEGORI',
          type: 'TEXT',
          customRender: (item) => {
            return `${item?.categoryName ?? '-'}`
          },
        },
        {
          id: 'day',
          label: 'HARI KE',
          type: 'TEXT',
          width: '63px',
          customRender: (item) => {
            return `Ke ${item?.showOnDay ?? '-'}`
          },
        },
        {
          id: 'aksi',
          label: 'AKSI',
          type: 'BUTTON',
          itemAlign: 'end',
          width: '80px',
          customRender: (item) => {
            return {
              buttonText: 'LIHAT',
              onButton: {
                click: () => {
                  this.$router.push({
                    name: this.isRoleNutritionist ? 'content-challenge.detail' : 'admin.coaching-challenge.detail',
                    params: { id: item.id },
                  })
                },
              },
              bindButton: {
                variant: 'outlined',
              },
            }
          },
        },
      ],
      search: this.$route.query.q ?? '',
      timer: null,
    }
  },
  computed: {
    selectedMonth() {
      return this.$route.query.month ? this.$route.query.month : null
    },
    categoryParse() {
      return this.$route.query?.category ? JSON.parse(this.$route.query?.category) : []
    },
    subCategoryParse() {
      return this.$route.query?.subCategories ? JSON.parse(this.$route.query?.subCategories) : []
    },
    getPreviewImage() {
      if (this.selectedEditDataCopy.newImage) {
        return URL.createObjectURL(this.selectedEditDataCopy.newImage)
      }
      if (this.selectedEditDataCopy.image && typeof this.selectedEditDataCopy.image == 'string') {
        return this.selectedEditDataCopy.image
      }
      return require('@/assets/icon-photo.svg')
    },
  },
  methods: {
    isDataEmpty,
    calculatePerPage(totalData, perPage) {
      return Math.ceil(totalData / perPage)
    },
    clickDetailContent(selectedMonth) {
      this.$emit('on-click', selectedMonth)
    },
    onBack() {
      this.$router.push({
        name: this.$route.name,
        query: {
          type: this.$route.query.type,
          page: 1,
          perpage: this.$route.query.perPage,
        },
        params: { savePosition: true },
      })
    },
    onSearch() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            q: this.search,
            page: 1,
          },
          params: { savePosition: true },
        })
      }, 1000)
    },
    changePage(page) {
      if (this.page != page) {
        this.$router.push({
          name: this.$route.name,
          query: { ...this.$route.query, page: page },
        })
      }
    },
    async onSubmit() {
      this.isLoadingEdit = true

      let newImage = null
      if (this.selectedEditDataCopy.newImage) {
        newImage = await reqUploadImageContentAndChallenge(
          this.$store.getters.axios,
          this.selectedEditDataCopy.newImage,
        ).then((v) => v.data?.data?.url)
      }

      await reqUpdateContentMonthGroup(
        this.$store.getters.axios,
        { id: this.selectedEditDataCopy.id },
        {
          title: this.selectedEditDataCopy.title,
          description: this.selectedEditDataCopy.description,
          images: newImage || this.selectedEditDataCopy.image,
        },
      ).finally(() => {
        this.isLoadingEdit = false
        this.isOpenModalEdit = false
        this.$emit('refetch-content-month-group', Date.now())
        this.isOpenModalEditSuccess = true
      })
    },
    changePerPage(perPage) {
      if (this.perPage != perPage) {
        this.$router.push({
          name: this.$route.name,
          query: { ...this.$route.query, page: 1, perpage: perPage },
          params: { savePosition: true },
        }).catch(() => {})
      }
    },
    onChangeCategory(value) {
      if (value == 'all') {
        this.$router.push({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            page: 1,
            category: '',
            subCategories: '',
          },
          params: { savePosition: true },
        }).catch(() => {})
      } else {
        let category = this.categoryParse
        if (category && category?.some((it) => it == value)) {
          category = category?.filter((it) => it != value)
        } else {
          category.push(value)
        }
        category = JSON.stringify(category)
        this.$router.push({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            page: 1,
            category: category.length == 0 ? '' : category,
            subCategories: '',
          },
          params: { savePosition: true },
        }).catch(() => {})
      }
    },
    onChangeFilter(value) {
      let subcategory = this.subCategoryParse
      if (subcategory && subcategory?.some((it) => it == value)) {
        subcategory = subcategory?.filter((it) => it != value)
      } else {
        subcategory.push(value)
      }
      subcategory = JSON.stringify(subcategory)
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          page: 1,
          category: '',
          subCategories: subcategory.length == 0 ? '' : subcategory,
        },
        params: { savePosition: true },
      }).catch(() => {})
    },
    handleOpenFile() {
      if (this.$refs['file-']) {
        this.$refs['file-']?.click()
      }
    },
    handleChangePhoto(type, e) {
      if (type === 'input-photo') {
        this.selectedEditDataCopy.newImage = e.target.files?.[0]
        return
      }
    },
  },
}
</script>

<style scoped>
::v-deep .card:hover .card-options {
  opacity: 1;
}

</style>
