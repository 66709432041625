var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "flex-grow": "1"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "margin-bottom": "20px",
      "paths": [{
        label: 'Manajemen Panduan Diet',
        href: '/admin/coaching-challenge'
      }, {
        label: "".concat(_vm.typeName),
        href: {
          name: 'admin.coaching-challenge',
          query: {
            type: _vm.type
          }
        },
        isCurrent: !_vm.$route.query.month
      }].concat(_vm.$route.query.month ? [{
        label: "Bulan ".concat(_vm.$route.query.month),
        isCurrent: true
      }] : [])
    }
  }), _c('c-box', {
    attrs: {
      "width": "100%",
      "max-width": "1270px",
      "background-color": "#FFF",
      "margin-bottom": "16px",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "padding": ['16px', '30px'],
      "min-height": ['unset', '74vh'],
      "display": "flex",
      "flex-direction": "column",
      "align-items": "center"
    }
  }, [_c('c-flex', {
    attrs: {
      "mb": "30px",
      "justify": "space-between",
      "align": "center",
      "width": "100%"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "20px",
      "size-desktop": "28px"
    }
  }, [_vm._v(" Manajemen Panduan Diet ")]), _c('c-flex', {
    attrs: {
      "gap": "3"
    }
  }, [_c('BaseButton', {
    attrs: {
      "border-radius": "16px",
      "variant": "outlined"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'admin.coaching-challenge.category',
          params: {
            type: _vm.type
          }
        });
      }
    }
  }, [_vm._v(" Manajemen Kategori ")]), _c('BaseButton', {
    attrs: {
      "left-svg-icon": require('@/assets/icons/icon-plus.svg'),
      "left-svg-icon-color": "white",
      "border-radius": "16px"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'admin.coaching-challenge.add',
          params: {
            type: _vm.type
          }
        });
      }
    }
  }, [_vm._v(" Tambah Konten ")])], 1)], 1), _c('ContentType', {
    attrs: {
      "type": _vm.type
    },
    on: {
      "click": function click(value) {
        return _vm.onChangeType(value);
      }
    }
  }), _vm.type === 'materi_harian' && !_vm.isLoadingListMonthMateriHarian ? _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('MateriHarian', {
    attrs: {
      "type": _vm.type,
      "months": _vm.listMonthMateriHarian,
      "items": _vm.items,
      "categories": _vm.category,
      "page": _vm.page,
      "per-page": _vm.perPage,
      "total": _vm.total
    },
    on: {
      "refetch-content-month-group": function refetchContentMonthGroup(v) {
        return _vm.refetchContentMonthGroup = v;
      },
      "on-click": _vm.onChangeMonth
    }
  })], 1) : _vm.type === 'target_harian' && !_vm.isLoadingListMonthTargetHarian ? _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('TargetHarian', {
    attrs: {
      "type": _vm.type,
      "months": _vm.listMonthTargetHarian,
      "items": _vm.items,
      "categories": _vm.category,
      "page": _vm.page,
      "per-page": _vm.perPage,
      "total": _vm.total
    },
    on: {
      "refetch-content-month-group": function refetchContentMonthGroup(v) {
        return _vm.refetchContentMonthGroup = v;
      },
      "on-click": _vm.onChangeMonth
    }
  })], 1) : _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('TipsGizi', {
    attrs: {
      "type": _vm.type,
      "items": _vm.items,
      "categories": _vm.category,
      "page": _vm.page,
      "per-page": _vm.perPage,
      "total": _vm.total
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }