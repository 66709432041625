<template>
  <c-box
    width="100%"
    mb="1rem"
  >
    <c-flex
      margin-bottom="16px"
      gap="16px"
      width="100%"
      align-items="center"
    >
      <BaseText
        size-mobile="18px"
        size-desktop="20px"
        white-space="nowrap"
      > 
        List Tips Gizi 
      </BaseText>

      <c-box width="100%">
        <c-form-control
          pos="relative"
          w="100%"
        >
          <c-image
            :src="require('@/assets/ic-search.svg')"
            alt="icon"
            position="absolute"
            top="50%"
            transform="translateY(-50%)"
            left="15px"
            height="24px"
            z-index="2"
          />
          <c-input
            id="search"
            v-model="search"
            type="text"
            w="100%"
            height="62px"
            placeholder="Pencarian"
            border-radius="20px"
            padding-left="50px"
            position="relative"
            z-index="1"
            @keyup="onSearch"
          />
        </c-form-control>
      </c-box>

      <c-menu
        :auto-select="false"
        :close-on-select="true"
        @blur="true"
      >
        <c-menu-button
          background-color="white"
          color="gray.900"
          p="0"
        >
          <inline-svg
            :src="require('@/assets/icons/icon-filter.svg')"
            height="30"
            width="30"
            fill="#888888"
          />
          <c-flex
            v-if="subCategoryParse.length > 0"
            pos="absolute"
            top="0"
            right="0" 
            width="16px"
            height="16px"
            align-items="center"
            justify-content="center"
            border-radius="100%"
            font-size="10px"
            background="#ef4444"
            color="#FFF"
          >
            {{ subCategoryParse.length }}
          </c-flex>
          <c-flex
            v-if="categoryParse.length > 0"
            pos="absolute"
            top="0"
            right="0" 
            width="16px"
            height="16px"
            align-items="center"
            justify-content="center"
            border-radius="100%"
            font-size="10px"
            background="#ef4444"
            color="#FFF"
          >
            {{ categoryParse.length }}
          </c-flex>
        </c-menu-button>
        <c-menu-list
          z-index="10"
          min-width="250px"
          max-width="300px"
          flex-direction="column" 
          p="0"
          placement="left-end"
        >
          <c-box
            v-for="(category, i) in categories"
            v-show="categories.length > 0"
            :key="category.id"
          >
            <c-menu-item
              v-chakra="{
                ':hover': {
                  backgroundColor: 'primary.50'
                }
              }"
              gap="8px"
              px="1rem"
              py="8px"
              cursor="pointer"
              :background-color="categoryParse.includes(category.id) ? 'primary.50' : '#FFF'"
              @click="onChangeCategory(category.id)"
            >
              <c-image
                :src="category?.image"
                width="25px"
                height="25px"
              />
              <c-text
                color="primary.400"
                font-size="18px"
              >
                {{ category?.categoryName }}
              </c-text>
            </c-menu-item>
            <c-menu-group 
              v-for="sub in category?.subCategories"
              :key="sub.id"
              title=""
            >
              <c-menu-item
                v-chakra="{
                  ':hover': {
                    background: subCategoryParse.includes(sub.id) ? 'primary.50' : '#F2F2F2',
                  }
                }" 
                d="flex" 
                justify-content="space-between"
                pl="48px"
                pr="8px"
                :background="subCategoryParse.includes(sub.id) ? 'primary.50' : '#FFF'"
                @click="onChangeFilter(sub?.id)"
              >
                {{ sub?.subCategoryName }}
                <inline-svg
                  :src="require('@/assets/icons/icon-chevron-right.svg')"
                  height="16px"
                  width="16px"
                />
              </c-menu-item>
            </c-menu-group> 
            <c-menu-divider
              v-if="categories.length !== (i+1)"
              p="0"
              m="0"
            />
          </c-box>
          <c-text
            v-show="categories.length == 0"
            px="1rem"
            font-size="16px"
            font-weight="500"
            color="danger.400"
          >
            Belum ada kategori
          </c-text>
        </c-menu-list>
      </c-menu>
    </c-flex>

    <c-box pos="relative">
      <c-flex
        v-dragscroll
        flex-direction="row"
        align-items="center"
        flex-wrap="no-wrap"
        gap="4px"
        overflow="hidden"
        max-width="890px"
        pb="8px"
      >
        <c-box
          v-chakra="{
            ':hover': {
              backgroundColor: subCategoryParse.length == 0 ? (categoryParse.length == 0 ? 'primary.50' : '#F2F2F2') : '#F2F2F2',
            },
          }"
          min-width="130px"
          max-width="150px"
          w="100%"
          border-radius="8px"
          :background-color="subCategoryParse.length == 0 ? (categoryParse.length == 0 ? 'primary.50' : '#FFF') : '#FFF'"
          text-align="center"
          p="6px"
          cursor="pointer"
          @click="onChangeCategory('all')"
        >
          <c-image
            src="https://ik.imagekit.io/dietela/pwa_webp/content_challenge/select%202.webp?updatedAt=1695810504668"
            w="60px"
            h="60px"
            mb="4px"
            mx="auto"
          />
          <c-text
            font-size="12px"
            color="#111"
            font-weight="500"
          >
            Semua
          </c-text>
        </c-box>
        <c-box
          v-for="item in categories"
          v-show="categories.length > 0"
          :key="item.id"
          v-chakra="{
            ':hover': {
              backgroundColor: categoryParse.includes(item.id) ? 'primary.50' : '#F2F2F2',
            },
          }"
          min-width="130px"
          max-width="150px"
          w="100%"
          border-radius="8px"
          :background-color="categoryParse.includes(item.id) ? 'primary.50' : '#FFF'"
          text-align="center"
          p="6px"
          cursor="pointer"
          @click="onChangeCategory(item.id)"
        >
          <c-image
            :src="item?.image"
            w="60px"
            h="60px"
            mb="4px"
            mx="auto"
          />
          <c-text
            font-size="12px"
            color="#111"
            font-weight="500"
          >
            {{ item?.categoryName }}
          </c-text>
        </c-box>
      </c-flex>
      <!-- <c-box
        pos="absolute"
        width="16px"
        height="100%"
        right="0"
        bottom="0"
        background="linear-gradient(to left, rgba(0,0,0,.2) 0%, rgba(0,0,0,0) 80%)"
      ></c-box> -->
    </c-box>

    <BaseTable2
      v-if="items.length > 0" 
      :columns="columns"
      :items="items"
      :page="page"
      :per-page="+perPage"
      :total-page="calculatePerPage(total, +perPage)"
      @on-change-page="(page_) => changePage(page_)"
      @on-change-per-page="(perPage_) => changePerPage(perPage_)"
    />
    <c-flex
      v-else
      w="100%"
      flex-direction="column"
      justify-content="center"
      align-items="center"
    >
      <c-image 
        src="https://ik.imagekit.io/dietela/pwa_webp/content_challenge/undraw_empty_re_opql%202.webp?updatedAt=1695810504720"
        width="300px"
        mt="3rem"
        mb="1rem"
      />
      <c-text
        :font-size="['16px', '18px']"
        font-weight="500"
        text-align="center"
      >
        {{ isRoleNutritionist ? `Saat ini konten belum tersedia` : `Konten belum ada, silahkan tambahkan konten${categoryParse.length > 0 || subCategoryParse.length > 0 ? ' untuk kategori ini' : ''}.` }}
      </c-text>
      <BaseButton
        v-if="isRoleAdmin"
        mt="1rem"
        border-radius="32px"
        width="300px"
        @click="$router.push({
          name: 'admin.coaching-challenge.add',
          params: {
            type: type,
          },
        })"
      >
        Tambah Konten
      </BaseButton>
    </c-flex>
  </c-box>
</template>

<script>
import { dragscroll } from 'vue-dragscroll'
import BaseText from '@/components/elements/base-text.vue'
import BaseTable2 from '@/components/elements/base-table-2.vue'
import BaseButton from '@/components/elements/base-button.vue'

export default {
  name: 'TipGizi',
  components: {
    BaseText,
    BaseTable2,
    BaseButton,
  },
  directives: {
    dragscroll,
  },
  props: {
    type: {
      type: String,
      default: 'tips-gizi',
    },
    items: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: String,
      default: '10',
    },
  },
  emits: ['on-search'],
  data() {
    return {
      columns: [
        {
          id: 'no',
          label: 'NO',
          type: 'AUTO_INCREMENT',
          align: 'center',
          itemAlign: 'center',
          width: '30px',
        },
        {
          id: 'title',
          label: 'JUDUL KONTEN',
          type: 'TEXT',
          width: '200px',
        },
        {
          id: 'preview',
          label: 'PREVIEW',
          type: 'CUSTOM',
          width: '250px',
          // customRender: (item) => {
          //   return {
          //     image: item?.coverImage,
          //     description: item?.description,
          //   }
          // }
          customRender: (item) => {
            if (item?.coverImage) {
              return `<div style="display: flex; align-items: center; ">
                  <img src="${item?.coverImage}" width="60" height="60px" style="margin-right: 1rem;" />
                  <div class="line-clamp-3">${item?.description}</div> 
                </div>
              `
            }
            return `<div class="line-clamp-3">${item?.description}</div>`
          },
        },
        {
          id: 'category',
          label: 'KATEGORI',
          type: 'TEXT',
          customRender: (item) => {
            return `${item?.categoryName}`
          },
        },
        {
          id: 'aksi',
          label: 'AKSI',
          type: 'BUTTON',
          itemAlign: 'end',
          width: '80px',
          customRender: (item) => {
            return {
              buttonText: 'LIHAT',
              onButton: {
                click: () => {
                  this.$router.push({
                    name: this.isRoleNutritionist ? 'content-challenge.detail' : 'admin.coaching-challenge.detail',
                    params: { id: item.id },
                  })
                },
              },
              bindButton: {
                variant: 'outlined',
              },
            }
          },
        },
      ],
      search: this.$route.query.q ?? '',
      timer: null,
    }
  },
  computed: {
    categoryParse() {
      return this.$route.query?.category ? JSON.parse(this.$route.query?.category) : []
    },
    subCategoryParse() {
      return this.$route.query?.subCategories ? JSON.parse(this.$route.query?.subCategories) : []
    },
  },
  methods: {
    calculatePerPage(totalData, perPage) {
      return Math.ceil(totalData / perPage)
    },
    onSearch() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            q: this.search,
            page: 1,
          },
          params: { savePosition: true },
        })
      }, 1000)
    },
    changePage(page) {
      if (this.page != page) {
        this.$router.push({
          name: this.$route.name,
          query: { ...this.$route.query, page: page },
        })
      }
    },
    changePerPage(perPage) {
      if (this.perPage != perPage) {
        this.$router.push({
          name: this.$route.name,
          query: { ...this.$route.query, page: 1, perpage: perPage },
          params: { savePosition: true },
        }).catch(() => {})
      }
    },
    onChangeCategory(value) {
      if (value == 'all') {
        this.$router.push({
          name: this.$route.name,
          query: { 
            ...this.$route.query,
            page: 1,
            category: '',
            subCategories: '',
          },
          params: { savePosition: true },
        }).catch(() => {})
      } else {
        let category = this.categoryParse
        if (category && category?.some((it) => it == value)) {
          category = category?.filter((it) => it != value)
        } else {
          category.push(value)
        }
        category = JSON.stringify(category)
        this.$router.push({
          name: this.$route.name,
          query: { 
            ...this.$route.query,
            page: 1,
            category: category.length == 0 ? '' : category,
            subCategories: '',
          },
          params: { savePosition: true },
        }).catch(() => {})
      }
    },
    onChangeFilter(value) {
      let subcategory = this.subCategoryParse
      if (subcategory && subcategory?.some((it) => it == value)) {
        subcategory = subcategory?.filter((it) => it != value)
      } else {
        subcategory.push(value)
      }
      subcategory = JSON.stringify(subcategory)
      this.$router.push({
        name: this.$route.name,
        query: { 
          ...this.$route.query,
          page: 1,
          category: '',
          subCategories: subcategory.length == 0 ? '' : subcategory,
        },
        params: { savePosition: true },
      }).catch(() => {})
    },
  },
}
</script>

<style></style>
